import React, { useState, useEffect } from "react";
import has from "lodash/has";
import clsx from "clsx";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Button, Divider, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import BackIcon from "@material-ui/icons/ArrowBack";

import ReloadIcon from "@material-ui/icons/Refresh";
import HomeIcon from "@material-ui/icons/Home";
import RefreshIcon from "@material-ui/icons/Refresh";
import config from "config";
// import LoadingIcon from "@material-ui/icons/HourglassEmptyRounded";
import TurtleIcon from "images/Turtle.icon";
import { getToken } from "api/index";
import dayjs from "dayjs";
import { setItem } from "utils/localStorage";
const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		// height: "100vh",
		minHeight: 300,
	},
	shift: {
		// marginTop: 64,
		margin: "auto",
	},
	loadingRotation: {
		animation: "rotation 4s infinite linear",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
	loadingIcon: {
		// margin: theme.spacing(2),
		fontSize: 64,
		color: has(theme, "palette.brand")
			? theme.palette.brand[500]
			: theme.palette.primary[500],
	},
	loadingImage: {
		height: 80,
		margin: 16,
	},
	centerButton: {
		display: "flex",
		maxWidth: 150,
		margin: "auto",
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	buttonRow: {
		// margin: "auto",
		marginTop: theme.spacing(2),
		// width: "80%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "start",
	},
	btn: {
		margin: theme.spacing(0, 2, 0, 2),
	},
}));

const useLoadingStyles = makeStyles((theme) => ({
	delayedDisplay: {
		width: "100%",
		animation: "0s linear 0.5s forwards $makeVisible",
		visibility: "hidden",
	},
	"@keyframes makeVisible": {
		to: {
			visibility: "visible",
		},
	},
	root: {
		margin: theme.spacing(2),
		// height: "100vh",
		minHeight: 200,
		// width: "100%",
	},
	loadingRotation: {
		animation: "rotation 4s infinite linear",
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
	loadingIcon: {
		// margin: theme.spacing(2),
		fontSize: 64,
		fill: has(theme, "palette.brand")
			? theme.palette.brand[500]
			: theme.palette.primary[500],
		color: has(theme, "palette.brand")
			? theme.palette.brand[500]
			: theme.palette.primary[500],
	},
	loadingImage: {
		height: 80,
		margin: 16,
	},
	message: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
	},
	messageActions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignContent: "center",
	},
}));

function DelayedLoadingSpinner(props) {
	const classes = useLoadingStyles();
	const theme = useTheme();
	const [count, setCount] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setCount((c) => c + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	return (
		<div className={clsx(classes.root, classes.delayedDisplay)}>
			<Typography align="center" variant="h6">
				Loading...
			</Typography>
			<TurtleIcon
				className={clsx(classes.loadingRotation, classes.loadingIcon)}
				style={{
					fill: has(theme, "palette.brand")
						? theme.palette.brand[500]
						: theme.palette.primary[500],
					fontSize: 64,
					height: 64,
					width: 64,
				}}
			/>

			{count > 20 && (
				<div className={classes.message}>
					<Typography gutterBottom align="center">
						There seems to have been an issue loading your requested resource.
						Please hit refresh to try again or go home.
					</Typography>
					<div className={classes.messageActions}>
						<Button
							startIcon={<RefreshIcon />}
							onClick={() => window.location.reload()}
						>
							Refresh
						</Button>
						<Divider orientation="vertical" flexItem />
						<Button
							startIcon={<HomeIcon />}
							component={RouterLink}
							to="/dashboard"
						>
							Go Home
						</Button>
					</div>
				</div>
			)}
			{/* <PoweredBy /> */}
		</div>
	);
}
function Error(props) {
	const classes = useStyles();
	const { message, refreshURL } = props;
	const history = useHistory();
	const goBack = () => window.history.back();
	const refresh = () => {
		let token = getToken();
		const localStorageKey = process.env.REACT_APP_localStorageKey;
		localStorage.clear();
		localStorage.setItem(localStorageKey, token);
		refreshURL && history.replace(refreshURL);
		window.location.reload();
	};
	const reload = () => {
		localStorage.clear();
		window.location.reload();
	};
	return (
		<div className={classes.root}>
			<Typography align="center" variant="h6">
				Error
			</Typography>
			<Typography variant="subtitle1" align="center">
				Seems like there was an issue loading the current resource.
			</Typography>
			{message && (
				<Typography variant="body1" align="center">
					{message}
				</Typography>
			)}
			<div className={classes.buttonRow}>
				<Button
					onClick={refresh}
					variant="contained"
					color="secondary"
					className={classes.btn}
				>
					<ReloadIcon className={classes.leftIcon} />
					Refresh
				</Button>
				<div
					style={{ display: "flex", flexDirection: "column" }}
					className={classes.btn}
				>
					<Button onClick={reload} variant="contained" color="secondary">
						<Warning className={classes.leftIcon} />
						Reload {config?.name}
					</Button>
					<small>Requires user to sign in again.</small>
				</div>
			</div>

			<div className={classes.buttonRow}>
				<Button
					onClick={goBack}
					variant="contained"
					color="secondary"
					className={classes.btn}
				>
					<BackIcon className={classes.leftIcon} />
					Go Back
				</Button>
				<Button variant="contained" color="secondary" onClick={() => {
					setItem("routeHistory", "/dashboard/portfolio/map");
					setItem("startDate", dayjs().startOf("month").subtract(12, "month").toDate());
					setItem("endDate", dayjs().startOf("month").toDate());
					history.push("/dashboard/portfolio/map");
					location.reload();
				}}>
					<HomeIcon className={classes.leftIcon} />
					Home
				</Button>
			</div>
		</div>
	);
}

export default DelayedLoadingSpinner;
export { Error };
