import { createTheme } from '@material-ui/core/styles';
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// import blueGrey from "@material-ui/core/colors/blueGrey";
import indigo from "@material-ui/core/colors/indigo";
// http://mcg.mbitson.com/#!?mcgpalette0=%23e21833
import config from "../config";
// import { tabsStyles, tabItemStyles } from "./theme.tabs";
let { brand } = config;
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/premium-themes/paperbase/Paperbase.js

let theme = createTheme({
	spacing: 8, // Default is 8
	typography: {
		// htmlFontSize: 14,
		// fontSize: 14,
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
	palette: {
		primary: indigo,
		secondary: grey,
		success: green,
		error: red,
		...(brand && { brand }),
	},
	shape: {
		borderRadius: 4,
	},
	props: {
		MuiTab: {
			disableRipple: true,
		},
	},
	mixins: {
		toolbar: {
			minHeight: 48,
		},
	},
});
theme = {
	...theme,
	overrides: {
		MuiCardHeader: {
			root: { padding: theme.spacing(2, 2, 1, 2) },
		},
		MuiCardContent: { root: { padding: theme.spacing(1, 2) } },
		MuiTableCell: {
			head: {
				fontWeight: 600,
			},
			sizeSmall: {
				padding: `6px 8px 6px 8px`,
			},
		},
		MuiButton: {
			label: {
				textTransform: "none",
			},
			contained: {
				boxShadow: "none",
				"&:active": {
					boxShadow: "none",
				},
				"&:hover": {
					boxShadow: "none",
				},
			},
		},
		// MuiTabs: tabsStyles(theme),
		// MuiTab: tabItemStyles(theme),
		MuiIconButton: {
			root: {
				padding: theme.spacing(1),
			},
		},
		MuiTooltip: {
			tooltip: {
				borderRadius: 4,
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: "#a2a2a2",
				marginTop: theme.spacing(1),
				marginBottom: theme.spacing(1),
			},
		},
		MuiListItemText: {
			primary: {
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
		MuiListItemIcon: {
			root: {
				color: "inherit",
				marginRight: 0,
				"& svg": {
					fontSize: 20,
				},
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		},
	},
};
export default theme;
