import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { initializeGA } from "routes/gaTracker";
import config from "config";
// Components
import App from "./App";
// Styles And Theme
import "./styles/index.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "styles/theme.js";

// Cookie Consent
import CC from "cookieconsent";
import "cookieconsent/build/cookieconsent.min.css";

import { AppProviders } from "contexts";
const options = {
	container: document.getElementById("cookieconsent"),
	palette: {
		popup: { background: "#d2d2d2" },
		button: { background: "#3f51b5" },
	},
	"position": "bottom-right",
	cookie: {
		// This is the name of this cookie - you can ignore this
		name: config.cookieConsent.name || 'cookieconsent_status_umd',
		// This is the url path that the cookie 'name' belongs to. The cookie can only be read at this location
		path: config.cookieConsent.path || '/',
		// This is the domain that the cookie 'name' belongs to. The cookie can only be read on this domain.
		//  - Guide to cookie domains - https://www.mxsasha.eu/blog/2014/03/04/definitive-guide-to-cookie-domains/
		domain: config.cookieConsent.domain || 'umd.edu',
		// The cookies expire date, specified in days (specify -1 for no expiry)
		expiryDays: config.cookieConsent.expiryDays || 356,
		// If true the cookie will be created with the secure flag. Secure cookies will only be transmitted via HTTPS.
		secure: config.cookieConsent.secure || true
	},
	revokable: false,
	"content": {
		"header": config.cookieConsent.header || '',
		"message": config.cookieConsent.message || '',
		"dismiss": config.cookieConsent.dismiss || '',
		"allow": config.cookieConsent.allow || '',
		"deny": config.cookieConsent.deny || '',
		"link": config.cookieConsent.link || '',
		"href": config.cookieConsent.href || '',
		"href-deny": config.cookieConsent.hrefDeny || '',
		"close": config.cookieConsent.close || '',
		"policy": config.cookieConsent.policy || '',
		"target": config.cookieConsent.target || '',
	},
	elements: {
		header: '<span class="cc-header">{{header}}</span>&nbsp',
		allow:
			`<a aria-label="allow cookies" role=button tabindex="0"  class="cc-btn cc-allow">{{allow}}</a>`,
		deny:
			`<a href="{{href-deny}}" aria-label="deny cookies" role=button tabindex="0" class="cc-btn cc-deny">{{deny}}</a>`,
		dismiss:
			``,
	},
	// define types of 'compliance' here. '{{value}}' strings in here are linked to `elements`
	compliance: {
		info: '<div class="cc-compliance">{{allow}}{{deny}}</div>',
		'opt-in':
			'<div class="cc-compliance cc-highlight">{{dismiss}}{{allow}}{{customize}}</div>',
		'opt-out':
			'<div class="cc-compliance cc-highlight">{{dismiss}}{{deny}}</div>',
		categories: '<div class="form">{{categories}}{{save}}</div>'
	},
	"theme": "classic"
};
ReactDOM.render(
	<ThemeProvider theme={Theme}>
		<CssBaseline />
		<AppProviders>
			<App />
			{(!serviceWorker.isLocalhost) ? window.cookieconsent.initialise(options) : null}
		</AppProviders>
	</ThemeProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
if (!serviceWorker.isLocalhost) initializeGA();
