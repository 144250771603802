import { cache } from "swr";

const apiURL = process.env.REACT_APP_API_URL;
const localStorageKey = process.env.REACT_APP_localStorageKey;

export function getToken() {
	// if we were a real auth provider, this is where we would make a request
	// to retrieve the user's token. (It's a bit more complicated than that...
	// but you're probably not an auth provider so you don't need to worry about it).
	return window.localStorage.getItem(localStorageKey);
}
export const handleResponse = async (response) => {
	if (response.status === 401) {
		cache.clear();
		// await auth.logout();
		// window.localStorage.removeItem(localStorageKey);
		// refresh the page for them
		// window.location.assign(window.location);
		window.location.reload(true);
		return Promise.reject({ message: "Please re-authenticate." });
	}
	// const contentType = response.headers.get("content-type");
	const data = await response.json();
	if (response.ok) {
		return data;
	} else {
		return Promise.reject(data);
	}
};
export function handleBlobResponse(response) {
	return response
		.blob()
		.then((blob) => {
			return blob;
		})
		.catch((err) => Promise.reject(err));
}
export const fetchWithToken = (
	url,
	{ body, formData, ...customConfig } = {}
) => {
	const token = getToken();
	let headers = {};
	if (body) {
		headers = { ...headers, "content-type": "application/json" };
	}
	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}
	const config = {
		method: body || formData ? "POST" : "GET",
		headers,
		...customConfig,
	};
	if (body) {
		config.body = JSON.stringify(body);
	}
	if (formData) {
		config.body = formData;
	}

	return fetch(`${apiURL}/${url}`, config).then(handleResponse);
};
export const fetchBlob = (url, { body, formData, ...customConfig } = {}) => {
	const token = getToken();
	let headers = {};
	if (body) {
		headers = { ...headers, "content-type": "application/json" };
	}
	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}
	const config = {
		method: body || formData ? "POST" : "GET",
		headers,
		...customConfig,
	};
	if (body) {
		config.body = JSON.stringify(body);
	}
	if (formData) {
		config.body = formData;
	}

	return fetch(`${apiURL}/${url}`, config).then(handleBlobResponse);
};

export const DEFAULT_OPTIONS = {
	revalidateOnFocus: false,
	refreshInterval: 0,
	loadingTimeout: 8000,
	errorRetryCount: 4,
};
