import React from "react";
import { queryCache } from "react-query";
import * as auth from "utils/auth-provider";
import { client } from "utils/api-client";
import * as userAPI from "utils/users";
import { useAsync } from "utils/useAsync";
import Loading from "components/Status/Loading";
import Error from "components/Status/Error";
import { hasLocal, getLocal, saveLocal, getItem } from "utils/localStorage";
import config from "config";
import md5 from "md5";

const defaultNotifications = [
	{
		to:
			"/dashboard/portfolio/map?_portfolio=5e9909ba80b0c70a13544a62&date=2020-09-01T04%3A00%3A00.000Z",
		id: "bookmark-august-1",
		title: "HVAC Zone 1 September Analysis Available",
		text: "Check out the just released data for HVAC Zone 1 for September 2020",
		icon: "assignment",
	},
	{
		to:
			"/dashboard/portfolio/map?_portfolio=5e990ef780b0c70a13544adb&date=2020-09-01T04%3A00%3A00.000Z",
		id: "bookmark-august-8",
		title: "HVAC Zone 8 September Analysis Available",
		text: "Check out the just released data for HVAC Zone 8 for September 2020",
		icon: "assignment",
	},
];
function bookmarksCheck({ buildings, portfolio }) {
	if (!hasLocal("activePortfolio") && portfolio) {
		saveLocal(portfolio);
	}
	if (!hasLocal("recentBuildings") && buildings) {
		saveLocal("recentBuildings", buildings);
	}
}
async function bootstrapAppData() {
	let user = null;
	const token = await auth.getToken();
	if (token) {
		const data = await client("bootstrap", { token });
		user = data.user;
		// bookmarksCheck(user.bookmarks);
		user = {
			...user,
			notifications: defaultNotifications,
			showHVACReporting:
				!user.showHVACReporting && user.showHVACReporting !== false
					? user.scopes.includes("facilities")
					: user.showHVACReporting,
			// newUser: true,
		};

		// if (user?.expiresAt && new Date(user?.expiresAt) < new Date()) {
		// 	auth.logout();
		// 	queryCache.clear();
		// 	return {};
		// }
	}

	return user;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
	const {
		data: user,
		status,
		error,
		isLoading,
		isIdle,
		isError,
		isSuccess,
		run,
		setData,
	} = useAsync();

	React.useEffect(() => {
		const appDataPromise = bootstrapAppData();
		run(appDataPromise);
	}, [run]);

	// AUTHENTICATION
	const login = React.useCallback(
		(form) => auth.login(form).then((user) => setData(user)),
		[setData]
	);
	const thirdPartyLogin = React.useCallback(
		({ service, ticket }) =>
			auth.thirdPartyLogin({ service, ticket }).then((user) => setData(user)),
		[setData]
	);
	const register = React.useCallback(
		(form) => auth.register(form),//.then((user) => setData(user)),
		[setData]
	);

	const userHasScope = React.useCallback(
		(requiredScopes) => {
			let userScopes = user.scopes;
			// OVVERIDE ACCESS
			const tempAccess = getItem(`${md5(md5(user.email))}allbyusone`) || {};
			if (Object.keys(tempAccess).length > 0) {
				const keyCache = Object.keys(tempAccess).filter((k) => tempAccess[k] === true).map((k) => config.scopesToCache.filter((s) => s.cache === k)[0].scope);
				if (keyCache.length > 0) {
					keyCache.push("allbyme")
					userScopes = keyCache;
				}
			}
			// OVVERIDE ACCESS
			if (!Array.isArray(requiredScopes)) requiredScopes = [requiredScopes];
			if (!user || !user.hasOwnProperty("scopes")) return false;
			if (!requiredScopes || requiredScopes.length === 0) return true;
			return requiredScopes
				.map((s) => userScopes.indexOf(s))
				.some((s) => s !== -1);
		},
		[user]
	);
	const userHasEmail = React.useCallback(
		(requiredEmails) => {
			if (!Array.isArray(requiredEmails)) requiredEmails = [requiredEmails];
			if (!user || !user.email) return false;
			if (!requiredEmails || requiredEmails.length === 0) return true;
			return requiredEmails
				.map((s) => user.email === s)
				.some((s) => s);
		},
		[user]
	);
	const userHasScopeAccessPreview = React.useCallback(
		(requiredScopes) => {
			let userScopes = false;
			// OVVERIDE ACCESS
			if (user && user.email) {
				const tempAccess = getItem(`${md5(md5(user.email))}allbyusone`) || {};
				if (Object.keys(tempAccess).length > 0) {
					const keyCache = Object.keys(tempAccess).filter((k) => tempAccess[k] === true).map((k) => config.scopesToCache.filter((s) => s.cache === k)[0].scope);
					if (keyCache.length > 0) {
						userScopes = true;
					}
				} else {
					userScopes = false;
				}
			}
			// OVVERIDE ACCESS
			return userScopes;
		},
		[user]
	);
	const logout = React.useCallback(() => {
		auth.logout();
		queryCache.clear();
		setData(null);
	}, [setData]);

	// USER SETTINGS
	const setPortfolioBookmark = React.useCallback((_id) =>
		userAPI.setPortfolioBookmark(_id).then((bookmarks) => {
			return setData({ ...user, bookmarks });
		})
	);
	const setBuildingBookmark = React.useCallback((_id) =>
		userAPI.setBuildingBookmark(_id).then((bookmarks) => {
			return setData({ ...user, bookmarks });
		})
	);
	const updateUser = React.useCallback((changes) => {
		return setData(Object.assign(user, changes));
	});
	const clearAllNotifications = React.useCallback(() => {
		return setData(Object.assign(user, { notifications: [] }));
	});
	const value = React.useMemo(
		() => ({
			user,
			login,
			logout,
			register,
			updateUser,
			clearAllNotifications,
			thirdPartyLogin,
			userHasScope,
			userHasEmail,
			userHasScopeAccessPreview,
			setPortfolioBookmark,
			setBuildingBookmark,
		}),
		[
			user,
			login,
			logout,
			register,
			updateUser,
			clearAllNotifications,
			thirdPartyLogin,
			userHasScope,
			userHasEmail,
			setPortfolioBookmark,
			setBuildingBookmark,
		]
	);

	if (isLoading || isIdle) {
		return <Loading />;
	}

	if (isError) {
		return <Error error={error} />;
	}

	if (isSuccess) {
		return <AuthContext.Provider value={value} {...props} />;
	}

	throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
}

function useClient() {
	const { user } = useAuth();
	const token = user?.token;
	return React.useCallback(
		(endpoint, config) => client(endpoint, { ...config, token }),
		[token]
	);
}
function useAuthenticatedFetcher() {
	const { user } = useAuth();
	const token = user?.token;
}
export { AuthProvider, useAuth, useClient };
