// src: https://github.com/kentcdodds/bookshelf/blob/master/src/utils/hooks.js
import React from "react";

function useSafeDispatch(dispatch) {
	const mounted = React.useRef(false);
	React.useLayoutEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	}, []);
	return React.useCallback(
		(...args) => (mounted.current ? dispatch(...args) : void 0),
		[dispatch]
	);
}

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])
const defaultInitialState = { status: "idle", data: null, error: null };
function useAsync(initialState) {
	const initialStateRef = React.useRef({
		...defaultInitialState,
		...initialState,
	});
	const [{ status, data, error }, setState] = React.useReducer(
		(s, a) => ({ ...s, ...a }),
		initialStateRef.current
	);

	const safeSetState = useSafeDispatch(setState);

	const setData = React.useCallback(
		(data) => safeSetState({ data, status: "success" }),
		[safeSetState]
	);
	const setError = React.useCallback(
		(error) => safeSetState({ error, status: "error" }),
		[safeSetState]
	);
	const reset = React.useCallback(() => safeSetState(initialStateRef.current), [
		safeSetState,
	]);

	const run = React.useCallback(
		(promise) => {
			if (!promise || !promise.then) {
				throw new Error(
					`The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
				);
			}
			safeSetState({ status: "loading" });
			return promise.then(
				(data) => {
					setData(data);
					return data;
				},
				(error) => {
					setError(error);
					return error;
				}
			);
		},
		[safeSetState, setData, setError]
	);

	return {
		// using the same names that react-query uses for convenience
		isIdle: status === "idle",
		isLoading: status === "loading",
		isError: status === "error",
		isSuccess: status === "success",

		setData,
		setError,
		error,
		status,
		data,
		run,
		reset,
	};
}
function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
export { useAsync, timeout };
