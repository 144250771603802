import React from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import { useClient } from "contexts/auth-context";
import useSWR, { mutate as mutateCache } from "swr";
import { DEFAULT_OPTIONS, fetchWithToken, fetchBlob } from "./index";

const loadingErrorPage = {
    title: "Loading...",
    author: "loading...",
    body: {},
    files: [],
    loading: true,
};

const loadingErrorPages = Array.from({ length: 4 }, (v, index) => ({
    id: `loading-error-${index}`,
    ...loadingErrorPage,
}));

const errorPageQueryConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
};

const defaultMutationOptions = {
    onError: (err, variables, recover) =>
        typeof recover === "function" ? recover() : null,
    onSettled: () => {
        queryCache.invalidateQueries("errorPage");
        queryCache.invalidateQueries("errorPageSearch");
    },
};

function useAddErrorPage(options) {
    const client = useClient();
    return useMutation(
        (logs) => {
            return client(`errorpage`, {
                body: logs,
                method: "POST",
            });
        },
        {
            ...defaultMutationOptions,
            // ...options,
        }
    );
}

export {
    useAddErrorPage,
};
