import React from "react";
const defaultColor = "#9E9E9E";

// const SIZE = 16;

export default function TurtleIcon({
	style = { fill: defaultColor },
	...others
}) {
	return (
		<svg
			className="MuiSvgIcon-root"
			width="16px"
			height="16px"
			viewBox="0 0 16 16"
			{...style}
			{...others}
		>
			<g id="Turtle" fillRule="nonzero" {...style}>
				<path d="M6.07880435,3.86666667 C5.58695652,3.24777777 5.03804348,3.41222223 4.68478261,2.70916667 C4.11413044,1.57444444 5.22744565,-0.231666667 6.39130438,0.0244444444 C7.00978263,0.161666667 7.85271738,1.65888889 7.63858694,2.44722222 C7.42771738,3.22777777 6.5692935,3.3 6.07880435,3.86666667 M3.03804348,4.16944444 C2.85407609,4.83611111 2.17309783,5.32555556 2.11304348,6.03555556 C1.21358696,5.75861111 -0.318206522,3.68277778 1.08858696,2.95972223 C1.80380435,3.17472223 2.38913044,3.76527778 3.03804348,4.16972223" />
				<path d="M5.30978261,3.81111111 C5.63423913,3.98 5.82255435,4.54388889 6.10733696,4.82888889 C6.49592394,4.48 6.5557065,4.01305556 7.06440219,3.70666667 C9.39211956,3.74194444 10.6717391,7.47194444 10.2736413,9.39555556 C9.8125,11.6213889 7.09239131,13.0794444 5.70516304,14.7883333 C5.76766304,12.5458333 2.45081522,12.1205556 2.06521739,9.3125 C1.75190218,7.03472225 2.55027174,4.08 5.30978261,3.81055556 M3.53885869,7.93611113 C4.22527174,7.38527775 5.24456522,6.81833331 6.07826087,6.79444444 C6.10869565,7.26944444 6.20244565,7.76666669 6.10054348,8.19388888 C5.33858696,8.60416669 4.32717391,8.92638887 4.07146739,9.51833331 C4.71141304,9.14611112 5.51711956,8.535 6.18016304,8.58888888 C6.11929348,9.10361112 6.27472825,9.69583331 6.07228261,10.1611111 C5.59402174,10.1861111 4.92554348,10.7077777 4.69048913,11.0572223 C4.93505435,11.0872222 5.69538044,10.3666667 6.11114131,10.4675 C6.29103262,11.1425 5.87282609,11.9911111 6.21032609,12.4791667 C6.28315219,11.7730556 6.4125,11.0875 6.49836956,10.3852777 C6.87934781,10.5241667 7.2625,10.6622222 7.64592394,10.7952778 C7.36603262,10.2202777 6.83423912,10.3786111 6.50380437,9.94805556 C6.61086956,9.49861113 6.50706519,8.97305556 6.69510869,8.5525 C7.24184781,8.69694444 7.7744565,8.88416669 8.26739131,9.18861112 C7.8182065,8.47888888 7.10597825,8.53583331 6.57364131,8.06916669 C6.73125,7.63861112 6.52527175,7.07472225 6.6375,6.6275 C7.36630438,6.66027775 7.97092394,7.05527775 8.6380435,7.26916669 C8.05543481,6.20944444 6.6557065,6.80083331 6.39809781,5.44361111 C5.84918478,5.54 6.06521739,5.91027777 6.00244565,6.18027778 C4.97445652,6.64222225 3.59130435,7.36694444 3.53967391,7.935" />
				<path d="M11.2247283,2.85277777 C12.3214674,3.93611111 11.2546196,5.16527777 10.2391304,5.94083333 C9.91902175,5.20333333 9.54076088,4.63305556 9.10869562,4.21944444 C9.72065219,3.75555556 10.3505435,2.98722222 11.2247283,2.85277777 M2.33559783,11.24 C2.78070652,11.6844444 3.15543478,12.3333333 3.68559783,12.5288889 C2.83369565,13.1261111 2.67201087,13.9722222 1.64646739,14.5080556 C0.994565219,14.3175 0.407608696,13.9422223 0,13.0388889 C0.241847826,12.2472223 1.27445652,11.7380556 2.33505435,11.2388889 M12.3600544,12.9222222 C12.1576087,13.6083333 11.2807065,14.3180556 10.4997283,14.3797223 C9.90108694,13.6858333 9.30760869,12.9813889 8.64646738,12.4722222 C9.19809781,12.1091667 9.61385869,11.6961111 9.86114131,11.2238889 C10.8168478,11.4308333 11.7342391,11.7488889 12.3597826,12.9227777" />
			</g>
		</svg>
	);
}
export function OldTurtleIcon({ style = { fill: defaultColor }, ...others }) {
	return (
		<svg
			width="48px"
			height="48px"
			viewBox="0 0 48 48"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...others}
		>
			<g
				id="inactive"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
				opacity="0.66"
			>
				<g
					id="Turtle"
					transform="translate(1.250000, 0.000000)"
					fill-rule="nonzero"
					{...style}
				>
					<path
						d="M6.07880435,3.86666667 C5.58695652,3.24777777 5.03804348,3.41222223 4.68478261,2.70916667 C4.11413044,1.57444444 5.22744565,-0.231666667 6.39130438,0.0244444444 C7.00978263,0.161666667 7.85271738,1.65888889 7.63858694,2.44722222 C7.42771738,3.22777777 6.5692935,3.3 6.07880435,3.86666667 M3.03804348,4.16944444 C2.85407609,4.83611111 2.17309783,5.32555556 2.11304348,6.03555556 C1.21358696,5.75861111 -0.318206522,3.68277778 1.08858696,2.95972223 C1.80380435,3.17472223 2.38913044,3.76527778 3.03804348,4.16972223"
						id="Shape"
					/>
					<path
						d="M5.30978261,3.81111111 C5.63423913,3.98 5.82255435,4.54388889 6.10733696,4.82888889 C6.49592394,4.48 6.5557065,4.01305556 7.06440219,3.70666667 C9.39211956,3.74194444 10.6717391,7.47194444 10.2736413,9.39555556 C9.8125,11.6213889 7.09239131,13.0794444 5.70516304,14.7883333 C5.76766304,12.5458333 2.45081522,12.1205556 2.06521739,9.3125 C1.75190218,7.03472225 2.55027174,4.08 5.30978261,3.81055556 M3.53885869,7.93611113 C4.22527174,7.38527775 5.24456522,6.81833331 6.07826087,6.79444444 C6.10869565,7.26944444 6.20244565,7.76666669 6.10054348,8.19388888 C5.33858696,8.60416669 4.32717391,8.92638887 4.07146739,9.51833331 C4.71141304,9.14611112 5.51711956,8.535 6.18016304,8.58888888 C6.11929348,9.10361112 6.27472825,9.69583331 6.07228261,10.1611111 C5.59402174,10.1861111 4.92554348,10.7077777 4.69048913,11.0572223 C4.93505435,11.0872222 5.69538044,10.3666667 6.11114131,10.4675 C6.29103262,11.1425 5.87282609,11.9911111 6.21032609,12.4791667 C6.28315219,11.7730556 6.4125,11.0875 6.49836956,10.3852777 C6.87934781,10.5241667 7.2625,10.6622222 7.64592394,10.7952778 C7.36603262,10.2202777 6.83423912,10.3786111 6.50380437,9.94805556 C6.61086956,9.49861113 6.50706519,8.97305556 6.69510869,8.5525 C7.24184781,8.69694444 7.7744565,8.88416669 8.26739131,9.18861112 C7.8182065,8.47888888 7.10597825,8.53583331 6.57364131,8.06916669 C6.73125,7.63861112 6.52527175,7.07472225 6.6375,6.6275 C7.36630438,6.66027775 7.97092394,7.05527775 8.6380435,7.26916669 C8.05543481,6.20944444 6.6557065,6.80083331 6.39809781,5.44361111 C5.84918478,5.54 6.06521739,5.91027777 6.00244565,6.18027778 C4.97445652,6.64222225 3.59130435,7.36694444 3.53967391,7.935"
						id="Shape"
					/>
					<path
						d="M11.2247283,2.85277777 C12.3214674,3.93611111 11.2546196,5.16527777 10.2391304,5.94083333 C9.91902175,5.20333333 9.54076088,4.63305556 9.10869562,4.21944444 C9.72065219,3.75555556 10.3505435,2.98722222 11.2247283,2.85277777 M2.33559783,11.24 C2.78070652,11.6844444 3.15543478,12.3333333 3.68559783,12.5288889 C2.83369565,13.1261111 2.67201087,13.9722222 1.64646739,14.5080556 C0.994565219,14.3175 0.407608696,13.9422223 0,13.0388889 C0.241847826,12.2472223 1.27445652,11.7380556 2.33505435,11.2388889 M12.3600544,12.9222222 C12.1576087,13.6083333 11.2807065,14.3180556 10.4997283,14.3797223 C9.90108694,13.6858333 9.30760869,12.9813889 8.64646738,12.4722222 C9.19809781,12.1091667 9.61385869,11.6961111 9.86114131,11.2238889 C10.8168478,11.4308333 11.7342391,11.7488889 12.3597826,12.9227777"
						id="Shape"
					/>
				</g>
			</g>
		</svg>
	);
}
