import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { MainContent } from "containers/Page.js";
import ReloadIcon from "@material-ui/icons/Refresh";
import { getItem, setItem } from "utils/localStorage";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		// minWidth: 400,
		[theme.breakpoints.up("md")]: {
			minWidth: 600,
		},
	},
}));

const ErrorPage = () => {
	const history = useHistory();
	const classes = useStyles();
	return (
		<MainContent>
			<Grid
				container
				direction="column"
				alignItems="center"
				className={classes.root}
			>
				<Grid item>
					<Card className={classes.container}>
						<CardContent>
							<Typography variant="h5" align="center" gutterBottom>
								Page Not Found :(
							</Typography>
							<Typography>
								You may report this error via the{" "}
								<Link to={"/user/feedback"}>Feedback portal</Link>.
							</Typography>
							<Divider />
							<Typography>
								Additionally you can try to refresh the page by holding{" "}
								<code>Shift</code> + clicking the reload button (
								<ReloadIcon style={{ fontSize: "inherit" }} />) on your browser.
							</Typography>
						</CardContent>
						<CardActions style={{ justifyContent: "space-between" }}>
							<Button variant="outlined" onClick={() => {
								history.goBack();
							}}>
								Back
							</Button>
							<Button variant="outlined" onClick={() => {
								setItem("routeHistory", "/dashboard/portfolio/map");
								setItem("startDate", dayjs().startOf("month").subtract(12, "month").toDate());
								setItem("endDate", dayjs().startOf("month").toDate());
								history.push("/dashboard/portfolio/map");
								location.reload();
							}}>
								Home
							</Button>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</MainContent>
	);
};

export default ErrorPage;
