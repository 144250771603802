import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		//  display: "flex",
		//   flexDirection: "column",
		width: "100%",
		overflowX: "auto",
	},
	mainContent: {
		flex: 1,
		padding: theme.spacing(0.5),
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(2),
		},
		display: "flex",
		flexDirection: "row",
		// height: "100%"
	},
}));

function Page(props) {
	const classes = useStyles();
	const { children } = props;
	return <div className={classes.root}>{children}</div>;
}
function MainContent(props) {
	const classes = useStyles();
	const { children } = props;
	return <main className={classes.mainContent}>{children}</main>;
}

// export default ;
export { Page, MainContent };
