// https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
import ReactGA from "react-ga4";

export const initializeGA = () => {
	ReactGA.initialize(String(process.env.REACT_APP_GA_ID), { testMode: process.env.NODE_ENV === "development" });
};
export const logPageView = (history) => {
	//if (process.env.NODE_ENV !== "development") {
	history.listen((location) => {
		const page = location.pathname || window.location.pathname;
		//ReactGA.set({ page: page });
		//ReactGA.pageview(page);
		// ReactGA.send(page);
		ReactGA.send({
			hitType: 'pageview',
			page: page
		});
		// console.log(`Page View logged for: ${page}`, 'page');
	});
	//}
};
