import { queryCache } from "react-query";
import * as auth from "utils/auth-provider";
const apiURL = process.env.REACT_APP_API_URL;
import { cache } from "swr";
export function handleBlobResponse(response) {
	return response
		.blob()
		.then((blob) => {
			return blob;
		})
		.catch((err) => Promise.reject(err));
}

async function client(
	endpoint,
	{
		body,
		formData,
		query,
		token,
		blob,
		headers: customHeaders,
		...customConfig
	} = {}
) {
	const config = {
		method: body || formData ? "POST" : "GET",
		body: body ? JSON.stringify(body) : formData ? formData : undefined,
		headers: {
			Authorization: token ? `Bearer ${token}` : undefined,
			...(body && { "Content-Type": "application/json" }),
			...customHeaders,
		},
		...customConfig,
	};
	let url = `${apiURL}/${endpoint}`;
	if (query) {
		url = url + "?" + new URLSearchParams(query).toString();
	}
	return window.fetch(url, config).then(async (response) => {
		if (response.status === 401) {
			queryCache.clear();
			cache.clear();
			await auth.logout();
			// refresh the page for them
			// window.location.assign(window.location);
			window.location.reload(true);
			return Promise.reject({ message: "Please re-authenticate." });
		}
		// const contentType = response.headers.get("content-type");
		if (blob) {
			return handleBlobResponse(response);
		} else {
			const data = await response.json();
			if (response.ok) {
				return data;
			} else {
				return Promise.reject(data);
			}
		}
	});
}
async function fetcher(
	endpoint,
	{
		body,
		formData,
		query,
		token,
		blob,
		headers: customHeaders,
		...customConfig
	} = {}
) {
	const config = {
		method: body || formData ? "POST" : "GET",
		body: body ? JSON.stringify(body) : formData ? formData : undefined,
		headers: {
			Authorization: token ? `Bearer ${token}` : undefined,
			...(body && { "Content-Type": "application/json" }),
			...customHeaders,
		},
		...customConfig,
	};
	let url = `${apiURL}/${endpoint}`;
	if (query) {
		url = url + "?" + new URLSearchParams(query).toString();
	}
	return window.fetch(url, config).then(async (response) => {
		if (response.status === 401) {
			queryCache.clear();
			cache.clear();
			await auth.logout();
			// refresh the page for them
			// window.location.assign(window.location);
			window.location.reload(true);
			return Promise.reject({ message: "Please re-authenticate." });
		}
		// const contentType = response.headers.get("content-type");
		if (blob) {
			return handleBlobResponse(response);
		} else {
			const data = await response.json();
			if (response.ok) {
				return data;
			} else {
				return Promise.reject(data);
			}
		}
	});
}

export { client, fetcher };
