import React from "react";
import { homepage } from "../../package.json";
import { AuthProvider } from "./auth-context";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryConfigProvider } from "react-query";

const queryConfig = {
	shared: {
		// suspense: true,
	},
	queries: {
		staleTime: Infinity, // 1 hours
		cacheTime: Infinity, //12 hours
		useErrorBoundary: true,
		refetchOnWindowFocus: false,
		retry: 3,
		refetchInterval: false,
		refetchOnMount: false,
	},
};

let basename = "";
if (homepage) {
	const fullUrl = new URL(homepage);
	basename = fullUrl.pathname.endsWith("/")
		? fullUrl.pathname.slice(0, fullUrl.pathname.length - 1)
		: fullUrl.pathname;
}

function AppProviders({ children }) {
	return (
		<ReactQueryConfigProvider config={queryConfig}>
			<Router basename={basename}>
				<AuthProvider>{children}</AuthProvider>
			</Router>
		</ReactQueryConfigProvider>
	);
}
export { AppProviders };
